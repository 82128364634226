import {NgModule} from '@angular/core';

import {MenuItems} from './menu-items/menu-items';
import {
    AccordionAnchorDirective,
    AccordionLinkDirective,
    AccordionDirective
} from './accordion';
import {AjaxValidatorService} from './validator/ajax-validator.service';

@NgModule({
    declarations: [
        AccordionAnchorDirective,
        AccordionLinkDirective,
        AccordionDirective
    ],
    exports: [
        AccordionAnchorDirective,
        AccordionLinkDirective,
        AccordionDirective
    ],
    providers: [
        MenuItems,
        AjaxValidatorService,
    ]
})
export class SharedModule {
}
